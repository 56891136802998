var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"dashboard"},[_c('router-link',{staticClass:"rounded-button float-right bg-gradient-primary",attrs:{"to":_vm.link('/create')}},[_vm._v("+")]),_c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title"},[_vm._m(0),_vm._v(" "+_vm._s(_vm.$options.label))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 grid-margin"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("Listando "+_vm._s(_vm.$options.label))]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',[(_vm.listing)?_c('tr',[_c('td',{attrs:{"colspan":"10"}},[_c('b-skeleton-table',{attrs:{"rows":3,"columns":4,"table-props":{ bordered: true, striped: true }}})],1)]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_vm._m(2,true),_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.code))]),_c('td',[_vm._v(_vm._s(item.development.name))]),_c('td',[_vm._v(_vm._s(item.design.name))]),_c('td',[_c('statusSelector',{attrs:{"selected":item.status,"route":'properties',"item_id":item.id,"state":[
                      {label:'Disponible',value:'available','statusColor':'green'},
                      {label:'Vendido',value:'sold','statusColor':'red'},
                      {label:'Apartado',value:'sold','statusColor':'yellow'},

                  ]}})],1),_c('td',[_c('router-link',{staticClass:"action-link",attrs:{"to":_vm.link('/update/'+item.id)}},[_c('i',{staticClass:"mdi mdi-square-edit-outline"})]),_c('a',{staticClass:"action-link",on:{"click":function($event){return _vm.deleteMe(item.id,index)}}},[_c('i',{staticClass:"mdi mdi-delete-outline"})])],1)])})],2)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"page-title-icon bg-gradient-primary text-white mr-2"},[_c('i',{staticClass:"mdi mdi-check"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('input',{attrs:{"type":"checkbox"}})]),_c('th',[_vm._v(" ID ")]),_c('th',[_vm._v(" SKU/Clave ")]),_c('th',[_vm._v(" Desarrollo ")]),_c('th',[_vm._v(" Modelo ")]),_c('th',[_vm._v(" Estatus ")]),_c('th',[_vm._v(" Acciones ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('input',{attrs:{"type":"checkbox"}})])
}]

export { render, staticRenderFns }