<template>


<a class="statusSelector" @blur="closeSelector" @click="openSelector" href="javascript:void(0)">

    <span class="selector-toggler"><span class="bullet status-selected" :class="statusColor"></span> {{ label }}</span>
    <ul v-if="showState">
        <li v-for="(v,i) in state" :key="i">
            <a v-if="!v.hasOwnProperty('hidden')" href="javascript:void(0)" @click="changeStatus(i)"><span class="bullet" :class="v.statusColor"></span> {{ v.label }}</a>
        </li>
    </ul>
    <b-spinner v-if="loading" class="float-right spinner" small label="Cargando..."></b-spinner>
</a>


</template>


<script>

import api from '@/util/api.js';


export default{
    
    name:'statusSelector',

    data(){

        return {

            loading:false,
            current:'',
            statusColor:'',
            showState:false,
            label:''


        }

    },

    props:{

        state:{
            type:Array,
            required:true,
        },
        selected:{
            type:String,
            required:false,
            default:''
        },
        item_id:{

            type:Number,
            required:true
        },
        route:{
            type:String,
            required:true,

        },
        editable:{
            type:Boolean,
            required:false,
            default:true

        }

    },

    methods:{

        changeStatus(index){


            const item=this.state[index];
            this.loading=true;
            api.patch('/'+this.route+'/'+this.$props.item_id,{
                status:item.value
            }).then(()=>{

                this.statusColor=item.statusColor;
                this.label=item.label;
                this.$notify({message:"Cambió el estado a '"+this.label+"'",type:"success"});
                
            }).catch(()=>{
                
                this.$notify({message:"No se pudo actualizar el estado",type:"error"});

            }).finally(()=>{
                this.loading=false;
            });

        },
        openSelector(){

            if(this.editable){
                this.showState=true;
            }


        },
        closeSelector(){

            
            const self=this;
            setTimeout(function(){
                self.showState=false;
                
            },150);

        }


    },

    mounted(){



        this.current=this.$props.selected;

        let selectedItem=this.$props.state.find(e=>{
            if(e.value==this.current){
                return e;
            }

        });

        if(selectedItem==undefined){
            selectedItem=this.$props.state[0];
        }


        this.statusColor=selectedItem.statusColor;
        this.label=selectedItem.label;

    }




}



</script>

<style lang="scss" scoped>

.statusSelector{

    border:1px solid #ebedf2;
    border-radius: 50px;
    padding:4px 18px 4px 4px;
    a{text-decoration: none;}
    margin-right:10px;

    .spinner{position:absolute;right:0}
    .selector-toggler{
        color:#000
    }

    position:relative;
ul{
    position: absolute;
    z-index: 1;
    top: -200%;
    background: #fff !important;
    -webkit-box-shadow: 1px 1px 1px #ccc;
    box-shadow: -1px 2px 8px #ccc;
    border-radius: 3px;
    max-height: 110px;
    padding: 4px 20px;
    overflow-y: auto;
    li{
        list-style-type: none;

        a{
            color:#000
        }
    }
}

}


.bullet{

    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 0.5rem;
    position: relative;
    top: -2px;
    left: 5px;

&.red{
  background:linear-gradient(to right, rgba(255, 191, 150, 1), rgba(254, 112, 150, 1));
}

&.green{
  background: linear-gradient(to right, rgba(6, 185, 157, 1), rgba(132, 217, 210, 1));
}
&.gray{
  background: linear-gradient(to right, rgb(164, 164, 164), rgb(233, 233, 233));
}
&.yellow{
  background: linear-gradient(to right, rgb(255, 229, 81), rgb(239, 212, 8));    
}
&.blue{
  background: linear-gradient(to right, rgb(163, 219, 247), rgb(52, 195, 231));    
}

}



</style>